<template>
    <div class="body-left-box nav-menus" :class="{'close-left-box':isCloseBox}">
        <ul >
            <li @click="addTag(i)" :key="index" v-for="(i,index) in menuList" :class="{'menu-isOpen':i.isOpen,'first-menu':true,'current':i.current}">
                <a href="javascript:;" :class="['bg']">
                    <i :class="i.icon =='' ? 'first-menu-icon' : ('first-menu-icon ' +i.icon)" ></i>
                    <span v-text="i.name"></span>
                    <!--<div v-if="!(i.children instanceof Array)">
                    <router-link :to="i.path">{{i.name}}</router-link>
                </div>-->
                    <!-- <s :class="{'menu-up':i.isOpen,'menu-down':!i.isOpen}" v-if="i.children instanceof Array"></s> -->
                    <s v-if="i.children" :class="{'menu-up':i.isOpen,'menu-down':!i.isOpen}"></s>
                </a>
                <ul v-if="i.children instanceof Array">
                    <li @click.stop="addTag(m,[index,index1])" v-for="(m,index1) in i.children" :class="{'menu-isOpen':m.isOpen,'second-menu':true}">
                        <a href="javascript:;" :class="{'open-page-current':m.openPage}">
                            <i v-if="m.children" :class="{'second-menu-icon':!m.isOpen,'second-menu-icondown':m.isOpen}"></i>
                            <span v-text="m.name" :class="{'second-menu-color':m.isOpen}"></span>
                            <s :class="{'current-arrow':m.openPage}"></s>
                        </a>
                        <ul v-if="m.children instanceof Array">
                            <li class="third-menu" v-for="(n,index2) in m.children" @click.stop="addTag(n,[index,index1,index2])">
                                <a href="javascript:;" :class="{'open-page-current':n.openPage}">
                                    <i v-if="n.children" class="third-menu-icon"></i>
                                    <span v-text="n.name"></span>
                                    <s :class="{'current-arrow':n.openPage}"></s>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
        <span class="close-menu" @click="closeMenu()" ><i></i></span>
        <div style="position: absolute;left: 0;bottom: 0;padding: 15px;border-top: 1px solid #d5d5d5;background-color: #edeef1;width: 100%;" v-if="false">
			<div>
				<img src="../../assets/logo/waas@2x.png" alt="waas" style="height: 11px;display: inline-block;vertical-align: middle;">  
				<span style="font-weight:bold;font-size:12px;display: inline-block;vertical-align: middle;margin: 0 10px 0 5px;">物流云执行体系</span>	
		  </div>
		  <div style="margin-top: 5px;">
			<span style="font-size:12px;display: inline-block;vertical-align: middle;">客服电话：</span>
			<span style="font-weight:bold;font-size:12px;display: inline-block;vertical-align: middle;">4008567150</span>
		  </div>
		</div>
    </div>
</template>

<script>
    import Event from '../../scripts/event.js';
    import Cookie from '@/scripts/cookie.js';
    import config from '@/scripts/config.js';
    import utils from '@/scripts/utils.js';
    export default {
        data: function () {
            return {
                menuList: [
                    {
                        name: "",
                        path: "",
                        isShow: true,
                        zIndex: 1
                    }
                ],
                openPageCurrent: null,
                isCloseBox: false
            }
        },
        mounted: function () {
            var domainName = this.Cookie.get(this.Cookie.domain);
            this.listenEvents();
            this.listenCustomerEvents();
        },
        methods: {
            initlizeMenus: function (platformType) {
                var data = {};
                data.platformType = platformType;
                this.$AjaxRequest.AjaxSubmit(window.AppConfig.apiBaseUrl+"omsapi/sysmenu/sysmenus", "get", data, result => this.menuList = result ,null, true);
            },
            initlizeTokenAndMenus: function (appId,flage) {
                console.log(flage)
                var me = this;
                var data = {AppId:appId,Flag:0};
                var forAppId = localStorage.getItem('APPID');
                localStorage.setItem('ActiveAppId',forAppId||appId); //记录实际的AppId
                if(flage){ //flage为true就是初始化
                    if(forAppId){
                        me.initlizeCustomerMenus(forAppId);
                        localStorage.removeItem('APPID');
                        return;
                    }else{
                        var viewDomain = me.Utils.getViewDomain()
                        if(!(viewDomain="" || viewDomain=="operations") ){
                            this.initlizeTokenAndMenus(appId,false);
                        }else{
                            me.initlizeCustomerMenus(appId);
                            return;
                        }
                    }
                    return
                }else {//flage为false 就是点击
                    console.log('xxxxxxxxxxxxxxxxxxxxxxxx')
                    if(forAppId){
                        me.initlizeCustomerMenus(forAppId);
                        return
                    }
                }

                this.$AjaxRequest.AjaxSubmit(window.AppConfig.apiBaseUrl+"omsapi/sysmenu/changetoken", "get", data, 
                    function(result){
                        if(result.IsSuccess){
                            if(result.Result.isExternal){
                                window.location.href = result.Result.appUrl+"?token="+result.Result.jwttoken+"&domain="+result.Result.domain+"&appId="+appId
                                return;
                            }
                            //修改TOKEN
                            localStorage.wmstoken=result.Result.jwttoken;
                            if(result.Result.domain) {
                                Cookie.set(Cookie.domain, result.Result.domain);
                            } else {
                                Cookie.set(Cookie.domain, "");
                            }
                            
                            //切换菜单
                            if(!flage){ //flage为false则为点击
                                localStorage.setItem('APPID',appId);
                                let url = result.Result.domain ? `/${config.subdomain}/${result.Result.domain}/home` : `/${config.subdomain}/home`
                                setTimeout(() => {
                                    window.location.href =  url;
                                },100)
                            }
                        }
                    },
                    null, true);
            },
            initlizeCustomerMenus: function (appId) {
                var data = {AppId:appId};
                Event.$emit("listenInitialWebsite");
                this.$AjaxRequest.AjaxSubmit(window.AppConfig.apiBaseUrl+"omsapi/sysmenu/changemenus", "get", data, result => {
                    console.log(window.location)
                    console.log(Cookie.get(Cookie.domain))
                    // window.location = ''
                    this.menuList = result
                } ,null, true);
            },
            listenEvents:function(){
                Event.$on("initlizeMenus", platformType=>this.initlizeMenus(platformType));
            },
            listenCustomerEvents:function(){
                Event.$on("initlizeCustomerMenus", (platformType,flage)=>this.initlizeTokenAndMenus(platformType,flage));
            },
            addTag: function (obj) {
                if (obj.children == null) {
                    if (this.openPageCurrent != null) {
                        this.openPageCurrent.openPage = false;
                    }``
                    obj.openPage = true;
                    obj.id = obj.path;
                    this.openPageCurrent = obj;                    
                    Event.$emit("addTag", obj);
                } else {
                    obj.isOpen = !obj.isOpen;
                    this.menuList.forEach(obj => {
                        obj.current = false;
                    });
                    obj.current = obj.isOpen;

                }
            },
            closeMenu: function () {
                this.isCloseBox = !this.isCloseBox;
                Event.$emit("isCloseLeftBox");
            }
            
        }
    }
</script>


