//【项目配置列表】
var projectSettings = [
    {
        name:"OMS_WLYD",
        logo:{
            headerlogo:require("../assets/logo/oms_logo.png"),
            loginLogo:require("../assets/logo/oms-logo-login.png"),
            loginLogoStyle:{'height':"30px", 'width': "144px"}
        },
        text:{
            copyright: "", //版权所有 © 物联云仓（成都）科技有限公司 | 保留一切权利 | 蜀ICP备12009201号-11    旗舰版V3.0
            telephone:"" //联系我们 4008-567-150
        }
    }
];

//【项目序号：切换项目修改序号即可】
var projectIndex = 0;

export default {
    logo: projectSettings[projectIndex].logo,
    text: projectSettings[projectIndex].text,
}

