<template>
    <div class="app-head clearfix">
        <div class="logo" style="display: flex;align-items:center;padding-top: 0;">
            <img v-bind:src="website.logo" style="height: 16px;">
            <span style="font-weight:bold;font-size:14px;padding-left: 5px;" v-if="domain">{{website.name}}</span>
            <span style="font-weight:bold;font-size:14px;padding-left: 5px;" v-else>物流服务平台</span>
        </div>    
         <!-- 固定菜单部分 -->
        <FixedMenu></FixedMenu>
        <ul class="head-btn-list clearfix">
            <!--<li>
            <div class="us-title">
                <el-radio-group v-model="searchType" @change="changeSearchType">
                    <el-radio :label="1" :disabled="isRightFilter">管理</el-radio>
                    <el-radio :label="2">客户</el-radio>
                </el-radio-group>
            </div>
            <div class="user-select" v-show="searchType==2">
                <el-select v-model="customerCode" placeholder="请选择" @change="changeCustomer" filterable style="width:170px;">
                    <el-option v-for="item in options"
                               :key="item.label"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </li>-->
            <li class="ac" style="width:90px;">
                <span class="z">{{loginName}}</span>
                <div class="account-man">
                    <span><s class="icon-h1"></s>{{loginName}}<u></u></span>
                    <div class="account-list">
                        <p @click="(tUserOptions.visible = true)">修改密码</p>
                        <p @click="logout">退出登录</p>
                    </div>
                </div>
            </li>
            <!--<li><s class="icon-h3"></s><a href="javascript:;">帮助</a></li>-->
        </ul>
        <!--修改密码-->
        <DialogEx :options="tUserOptions" title="修改密码" @onOk="changePwd_onOk" style="width:70%;margin-left:15%;">
            <ChangePwd ref="changeUserPwd"></ChangePwd>
            <template slot="ButtonGroupLeft">
                <el-button type="primary" @click="resetPwdForm">重置</el-button>
            </template>
        </DialogEx>

    </div>
</template>

<script>
    var pathName = window.location.pathname.substring(1);
    var vPath = pathName == '' ? '' : '/' + pathName.substring(0, pathName.indexOf('/'));
    var vSPA = pathName == '' ? '' : ('/' + pathName).replace(vPath, '');
    import PageSetting from '../../scripts/pagesetting.js'
    import Event from '../../scripts/event.js'
    import config from '@/scripts/config.js'

    export default {
        data: function () {
            return {
                s: {
                    height: "47px"
                },
                loginName: "",
                isRightFilter: false,
                customerCode: "",
                //memberCode: "",
                warehouseNo: "",
                options: [],
                memberinfos: [],
                warehouseinfos: [],
                searchType: 1,
                tUserOptions: {
                    visible: false
                },
                pageSetting: PageSetting,
                domain:'operations',
                website:{
                    logo:"",
                    name:""
                },

            }
        },
        methods: {
            logout: function () {
                var domain = this.Cookie.get(this.Cookie.domain);
                var logoutUrl="/" +config.subdomain + '/login';
                if (domain) {
                    logoutUrl='/'+config.subdomain + "/" + domain + '/login';
                }
                this.Cookie.delLoginCookie();
                localStorage.removeItem("omstoken");
                localStorage.removeItem("headertoken");
                localStorage.removeItem("IsSSOUser");
                this.$store.commit("clear");
                window.location.href = logoutUrl;
            },
            changeWarehouse: function (val) {
                $('.user-scrollcontent').css('margin-top', 0);
                this.Utils.lazy(() => {
                    Event.$emit("reloadAllTag");
                });
            },
            changePwd_onOk(options) {
                this.$refs.changeUserPwd.changePwd(options);
            },
            resetPwdForm() {
                this.$refs.changeUserPwd.resetForm();
            },
            loadLoginUser() {
                var _this = this;
                this.$ajax.send("omsapi/auth/currentUser", "get", {}, (data) => {
                    if (data && data.Result) {
                        _this.loginName = data.Result.RealName;
                        _this.isRightFilter = data.Result.IsRightFilter;
                        _this.searchType = _this.isRightFilter ? 2 : _this.searchType;
                    }
                });
            },
            listenInitialWebsite:function(){
                var me = this;
                Event.$on("listenInitialWebsite", ()=>{
                    me.inititalWebsite()
                });
            },
            inititalWebsite(){
                var me = this;
                var domain = me.Utils.getSubDomain()
                console.log(domain)
                if(domain && domain!="operations"){
                    this.$ajax.send(window.AppConfig.apiBaseUrl+"omsapi/auth/getwebsite", "get", {domain:domain}, data => {
                        if(data.IsSuccess && data.Result){
                            me.website.logo=data.Result.PlatformLogo;
                            me.website.name = data.Result.Name;
                            me.Utils.initialWebsite(data.Result);
                        }else{
                            // me.website={
                            //     logo:"../../logo.png",
                            //     name:"物流服务平台"
                            // }
                        }
                    }, null, false);
                } else {
                    me.website={
                        // logo:"../../logo.png",
                        // name:"物流服务平台"
                    }
                }
            }
        },
        mounted: function () {
            var _this = this;
            _this.loadLoginUser();
            Event.$emit("changeHeight", parseInt(_this.s.height, 10) + 1);
            Event.$emit("changeContentHeight", parseInt(_this.s.height, 10) + 1);
            window.onresize = function () {
                Event.$emit("changeHeight", parseInt(_this.s.height, 10) + 1);
                Event.$emit("changeContentHeight", parseInt(_this.s.height, 10) + 1);
            };
            //this.domain = this.Utils.getSubDomain();
            //this.inititalWebsite(this.domain);
            this.listenInitialWebsite();
        },
        components: {
            "ChangePwd": resolve => { require(['./changepwd.vue'], resolve) },
            "FixedMenu": resolve => { require(['./fixedmenu.vue'], resolve) },
        }
    }
</script>

<style  lang="scss">
    * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
    @import '../../assets/css/layout.scss';
</style>
